@import './../../../app/colors.variables';
@import "src/app/theme.module";

#gantt {
  height: 700px;
}


.custom-task {
  height: 10rem;
  border-radius: 0.5rem;
  background-color: #9f47ce;

  &.to-approve {
    background-color: $textGrey;
  }
}

.custom-task-background {
  border-radius: 0.5rem;
  height: 100%;
  background-color: #5C007A;
  //position: absolute;
  overflow: hidden;
}

.custom-task-wrapper {
  padding: 8px;
  color: #fff;
}

.custom-task-wrapper > * {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-task-img-wrapper {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 8px;
  background-color: #fff;
  overflow: hidden;
}

.custom-task-img {
  width: 32px;
}

.custom-task-title {
  font-weight: 600;
  font-size: 14px;
}

.typeof {
  margin-right: 0.5rem;
}

.custom-task-typeof {
  font-weight: 600;
  font-size: 11px;
  color: $secondaryColor
}

.custom-task-row {
  font-size: 11px;
}

.custom-task-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.dx-gantt .dx-row {
  height: 63px;
}
