@import "src/app/theme.module";

#edit-image {
  width: 100%;
  max-height: 90vh;
  padding: 1rem;

  .button {
    width: 8rem;
  }

  .box-image {
    max-height: 70vh;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
