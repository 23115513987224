#profile {
  padding: 1rem;

  .avatar {
    height: 8rem!important;
    width: 8rem!important;
    border-radius: 4rem;
    object-fit: contain;
  }

  .logo-input {
    background-color: white!important;
  }

  .link {
    cursor: pointer;
  }
}
