@import "src/app/theme.module";

#campaign-statistic-filters {
  margin-top: 1rem;

  .button {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    color: white;
    padding: 0.5rem;
    background-color: $primaryColor;
  }
}

.section {
  margin-block: .5rem;
}
