@import './../../../app/colors.variables';
@import "src/app/theme.module";

#create-company-data1 {
  .form {
    margin: 3rem 0;
  }

  .save-button {
    width: 100%;
  }
}

@media screen and (max-width: $xs) {
  #create-company-data {

  }
}
