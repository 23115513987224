@import "src/app/theme.module";

#campaign {
  margin-bottom: 2rem;
}


@media screen and (max-width: $xs) {
  #campaign {
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;
  }
}


