@import './../../../app/colors.variables';
@import "src/app/theme.module";

#create-company-data2 {

  .logo-section {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .logo-input-row {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .logo-input {
    margin-right: 1rem;
  }
  .company-profile {
    margin-bottom: 1rem;
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    border: 2px solid $primaryDarkColor;
  }

  .save-button {
    width: 50%;
  }
}

@media screen and (max-width: $xs) {
  #create-company-data {

  }
}
