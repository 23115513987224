@import "src/app/theme.module";

.reviewType {
  font-weight: bold;
  color: $primaryColor;
}

.step {
  font-weight: bold;
}

.link {
  cursor: pointer;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

#cart-page-detail {
  margin-top: 2rem;
  justify-content: center;
  flex-direction: column;
  display: flex;
}
