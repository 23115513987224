@import "src/app/theme.module";

#product-page {
  padding: 2rem;

  .main-wrapper {
    display: grid;
    grid-template-columns: 3fr 6fr;
  }

  .header-container {
    flex-direction: row;
  }

  .separator-primary {
    border: 1px solid $primaryColor;
    color: $primaryColor;
  }
}

.businessUnit-avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  border: 2px solid $primaryColor;
  margin-right: 1rem;
}

.paypalButtons {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: 1;
}

.prod-text {
  font-size: 1.2rem;

  &.bold {
    font-weight: bold;
  }
}

.product-price-value {
  font-size: 1.3rem;
  color: $primaryColor;
  font-weight: bolder;
}

.buttonPayPal {
  width: 35rem
}

.paypalIcon {
  width: 6rem;
  padding: 0.5rem
}

.productTitle {
  font-size: 1.5rem;
  font-weight: bold;
}

.section-product-container {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.articleDetailMobile {
  display: none;
}

.articleDetailDesktop {
  display: block;
}

.img-cont {
  justify-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.image-preview {
  //-webkit-box-shadow: 1px 1px 44px -8px rgba(0,0,0,0.63);
  //box-shadow: 1px 1px 44px -8px rgba(0,0,0,0.63);
  max-height: 30rem;
  height: auto;
  align-self: center;
  //max-width: 38em;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 33vw;
  object-fit: contain;
}

.prod-show-more {
  margin-top: -0.5rem;
  width: 100%;
  text-align: center;
  color: $primaryColor;
  font-size: 0.8rem;
  cursor: pointer;
  text-decoration-line: underline;
  margin-bottom: 0.5rem;
}

.sectionPrivacyAndConditions {
  padding: 1rem;
}

.section-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.privacyContent {
  flex-direction: row;
  align-items: flex-start;
  width: 85%
}

.privacyText {

}

.textContainer {
  //padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.privacyLink {
  color: $primaryColor;
  text-decoration-line: underline;
  cursor: pointer;
}

.checkbox-container {
  width: 10%;
  justify-content: center;
  align-items: center;
}

.sectionRegisterForm {
  padding: 1rem;
  margin-bottom: 0;
  margin-top: 1rem;
}

@media screen and (max-width: $sm) {

  .articleDetailMobile {
    display: block;
  }

  .articleDetailDesktop {
    display: none;
  }

  .paypalButtons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .buttonPayPal {
    width: 15rem
  }

  .businessUnit-avatar {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    border: 2px solid $primaryColor;
    margin-right: 1rem;
  }

  .section-product-container {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  #product-page {
    padding: 1rem;

    .header-container {
      flex-direction: row-reverse;
    }

    .main-wrapper {
      display: flex;
      flex-direction: column;
    }

    .sectionPrivacyAndConditions {
      margin-top: 1rem;
      padding: 0;
    }

    .box-privacy{
      padding-left: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  .sectionRegisterForm {
    padding: 0;
    margin-bottom: 1rem;
  }

  .image-preview{
    width: 100%;
    height: 22rem;
  }

  .image-preview {
    //-webkit-box-shadow: 1px 1px 44px -8px rgba(0,0,0,0.63);
    //box-shadow: 1px 1px 44px -8px rgba(0,0,0,0.63);
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

