@import "function";

@function font-family($map) {
  @return map-deep-get($map, "family") + ", " +
    map-deep-get($map, "fallback");
}

@function font-weight($map, $weight) {
  @return map-deep-get($map, "weights", $weight);
}

$primaryColor: #8E24AA;
$primaryDarkColor: #5C007A;
$secondaryColor: #FFC527;
$secondaryYellow: #FFD35D;
$secondaryLightColor: #90FFFF;
$primaryDarkBackground: #162B68;
$white: #FFF;
$black: #000;
$grapeMust: #01044B;
$textGrey: #707070;
$greyDefault: #D7D7D7;
$greyBackground: #D7D7D7;
$errorColor: #d40000;
$c-grey-mischka: #e1dde6;
$successColor: #34a853;

$backgroundGradient: linear-gradient(to bottom, $grapeMust 0%, $primaryDarkBackground 60%, $grapeMust 100%);
$standardGradient: linear-gradient(to right, $secondaryColor, $primaryColor) border-box;
$fuchsiaTurquoiseGradient: linear-gradient(to right, $primaryColor, $secondaryLightColor 80%);

$t-primary: (
  family: Nunito,
  weights: (
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700
  ),
  fallback: sans-serif,
);

$t-secondary: (
  family: Raleway,
  weights: (
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700
  ),
  fallback: sans-serif,
);


$t-primary-font-family: font-family($t-primary);
$t-secondary-font-family: font-family($t-secondary);

//Breakpoints
$xs: 599px;
$sm: 1023px;
$md: 1439px;
$lg: 1919px;
$xl: 1920px;

//Spacings
$spacing-0: 0px;
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 16px;
$spacing-4: 32px;
$spacing-5: 64px;


//EXPORT
:export {
  white: $white;
  grey: $textGrey;
  greyDefault: $greyDefault;
  spacing-1: $spacing-1;
  spacing-2: $spacing-2;
  spacing-3: $spacing-3;
  spacing-4: $spacing-4;
  grapeMust: $grapeMust;
  black: $black;
  primaryColor: $primaryColor;
  primaryDarkColor: $primaryDarkColor;
  secondaryColor: $secondaryColor;
  secondaryYellow: $secondaryYellow;
  errorColor: $errorColor;
  fuchsiaTurquoiseGradient: $fuchsiaTurquoiseGradient;

  // just the family names (eg. "Montserrat")
  typeface-primary: map-deep-get($t-primary, 'family');
  typeface-secondary: map-deep-get($t-secondary, 'family');

  // actual font-family values (eg. "'Montserrat', sans-serif")
  family-primary: $t-primary-font-family;
  family-secondary: $t-secondary-font-family;

  // font-weights list (eg "300, 600, 800")
  weights-primary: map-values(map-deep-get($t-primary, 'weights'));
  weights-secondary: map-values(map-deep-get($t-secondary, 'weights'));
}
