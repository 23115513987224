@import './src/app/colors.variables';
@import './src/app/typography.variables';
@import './../../../app/function';

.b-card-signup{
  display: flex;
  justify-content: center;

  h1{
    font-size: 24px;
    margin-block-start: 0;
    margin-block-end: 1em;
  }

  a {
    color: $c-brand-purple;
  }

  .e-input-text{
    & + .e-input-text{
      margin-top: 10px;
    }
  }

  .e-checkbox{
    & + .e-checkbox{
      margin-block-start: 1em;
    }

  }

  .e-button{
    margin-block-start: 1em;
  }

  &__clear-email{
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    margin-inline-start: .5em;
    padding:0;
    background:none;
    border:none;
    outline:0;

    svg{
      display: block;
      flex-basis: 100%;
      height:100%;
      width:100%
    }
  }

  &__social{
    margin-block-end: 1em
  }

  &__tip{
    text-align:center;
    color: $c-grey-dove;
  }

  &__warn{
    color: $c-danger
  }

  &__content{

    > :last-child{
      margin-block-end: 0
    }
  }
}
