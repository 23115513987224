@import './../../../app/colors.variables';
@import "src/app/theme.module";

#campaign-contact-type {
  .sectionPrice {
    width: 10rem;
    height: 2rem;
    border-radius: 0.5rem;
    background-color: $greyDefault;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
}
