.section {
  margin-top: 0.5rem;
}

.close-icon {
  margin-left: 1rem;
}

.icon-delete-image-video {
  position: absolute;
  align-self: center;
  justify-content: center;
  flex: 1;
  top: 0;
  right: 0;
  //background-color: rgba(255,255,255, 0.8);
}

.video-content {
  width: 10rem;
  height: 10rem;
}

.errorColor {
  color: red;
  font-size: 0.8rem;

  &.text {
    padding-left: 0.5rem;
  }
}
