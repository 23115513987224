@import "src/app/theme.module";

.value-content {
  color: $secondaryColor;
  font-weight: bold;
}

.tooltip-container {
  //background-color: rgba(20, 0, 20, 1);
}
