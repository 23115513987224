@import './../../../app/colors.variables';
@import "src/app/theme.module";

#create-company-data4 {

  .grid-radio {
    margin: 3rem 0;
  }
  .save-button {
    width: 50%;
  }

}

@media screen and (max-width: $xs) {
  #create-company-data {

  }
}
