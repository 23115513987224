@import "src/app/theme.module";

#campaign-filters {
  padding-block: 1.5rem;

  .button {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    color: white;
    padding: 0.5rem;
    background-color: $primaryColor;
  }
}
