
#sent-email {

  .cursor {
    cursor: pointer;
  }

  .container {
    padding: 0.5rem 10rem;
  }

  .image {
    height: 50vh;
  }
}
