@import './../../../app/colors.variables';
@import "src/app/theme.module";

#sidebar {
  .item {
    height: 50px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .sidebar-item {
    height: 50px;
  }
}
