@import "src/app/theme.module";
.input-text{
  width: 90%;
  align-self: center;
  //height: 60px;
  // override mui styles

}


:export {
  //border-radius: $spacing-0
}
