@import './spacing.variables';
@import "src/app/theme.module";


.App {
  font-family: 'Kanit', sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-campaign-status {
  width: 10rem;
  height: 2rem;
  border-radius: 1rem;
  padding-inline: 0.2rem;

  &.to-be-approved {
    background-color: $secondaryColor;
    color: $black
  }

  &.active {
    background-color: $secondaryColor;
    color: $black
  }

  &.approved {
    background-color: $primaryColor;
    color: $white
  }

  &.draft {
    background-color: $greyBackground;
    $color: $white
  }

  &.dismissed {
    background-color: $greyBackground;
    $color: $white
  }
}
