@import "src/app/theme.module";
.input-date-picker {
  height: 60px;
  width: 90%;
  align-self: center;
  // override mui styles

}


:export {
  //border-radius: $spacing-0
}
