@import "src/app/theme.module";

.e-input-telephone {

  width: 90%;

  .MuiInputLabel-root {
    position: static;
    transform: none;
    color: $primaryColor;
    font-weight: bold;
    max-width: none;
    font-size: 1.125rem;
  }

  &.boldLabel {
    font-weight: bold;
  }
}

.e-input-telephone__container {
  display: grid;
  grid-template-columns: .5fr 2fr;
  grid-column-gap: 1rem;
}

.e-input-telephone__input {
  text-align: right;
}

.e-input-telephone__plus-sign {
  font-weight: bold;
}
