@import "src/app/theme.module";

  .icon {
    width: 40px;
    height: 40px;
  }

  .video-container {
    border: 2px solid $primaryColor;
    border-radius: 0.5rem;
  }

  .video {
    border-radius: 0.5rem;
    width: 100%;
    height: 10rem;
    object-fit: contain;
  }

  .video-wrapper {
    flex:1;
  }

  .video-background {
    border-radius: 0.3rem;
    width: 100%;
    height: 10rem;
    object-fit: fill;
    position: absolute;
    z-index: -1;
  }

  .icon-container {
    position: absolute;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .icon-delete-video {
    position: absolute;
    align-self: center;
    justify-content: center;
    flex: 1;
  }
