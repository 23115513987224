@import './../../app/colors.variables';
@import "src/app/theme.module";

#social-configurator {
  padding: 1rem;

  .form-label {
    padding-top: 0.5rem;
  }

  .tabs {
    width: 100%!important;
  }

  .tab {
    width: 50%;
    max-width: 50%!important;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .social-bio {
    margin-top: 1rem;
    width: 95%;
  }

  .social-container-configurator {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .image-container {
    border: 1px solid $primaryColor;
    flex: 1;
    width: 100%;
    border-radius: 8px;
    margin: 0;
    padding: 0;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.full {
      max-height: 30vh;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    &.empty {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-bottom: 1rem;
    }
  }

  .text {
    color: $primaryColor;
    font-weight: bolder;
  }

  .image-profile {
    width: 100%;
    height: auto;
    float: left;
    margin: 0;
    object-fit: contain;
    padding: 0;
  }

  .subtitle {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .file-button {
    margin-top: 1rem;
  }

  .image {
    width: 100%;
    max-width: 15rem;
    object-fit: contain;
  }

  .title {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

#instagram-wrapper {
  display: flex;
  justify-content: center;

  .instagram-preview {
    display: flex;
    width: 20vw;
    padding-inline: 0.5rem;
    padding-block: 0.5rem;
    border: 0.8px solid $greyDefault;
    border-radius: 0.5rem;
    flex-direction: column;

    .description {
      font-size: 0.8rem;
    }

    .instagram-preview-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 0.3rem;

      .time {
        font-size: 0.8rem;
        color: $textGrey;
        margin-top: -0.3rem
      }

      .ig-inf-avatar{
        height: 2rem;
        width: 2rem;
        border-radius: 1rem;
      }
    }

    .instagram-preview-body {
      .image-preview {
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
      }
      .video-player {
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
      }
    }
    .facebook-preview-bottom {
      flex-direction: row;
      display: flex;
      padding-inline: 0.5rem;
      margin-top: 0.5rem;
      justify-content: center;
      .fb-footer {
        width: 80%;
        align-self: center;
      }
    }
    .instagram-preview-bottom {
      flex-direction: row;
      display: flex;
      padding-inline: 0.5rem;
      margin-top: 0.5rem;
      justify-content: space-between;
      .icon {
        height: 1.3rem;
        width: 1.3rem;
        margin-right: 0.5rem;
      }
    }

    .instagram-preview-footer {
      display: flex;
      flex-direction: column;
      .description {
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  #social-configurator {
    padding: 0
  }

  .social-container-configurator {
    display: flex;
    flex-direction: column;
  }
}
