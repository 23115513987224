// Base colors
$c-pure-black: #000000;
$c-grey-dove: #6d6d6d;
$c-grey-mischka: #e1dde6;
$c-pure-white: #ffffff;

$c-jaguar: #140014;
$c-lightning-yellow: #ffc527;
$c-seance: #701787;
$c-red: #ff0001;
$c-chateau-green: #34a853;

// Aliases - brand
$c-brand-black: $c-jaguar;
$c-brand-black-75: #4f3f4f;
$c-brand-yellow: $c-lightning-yellow;
$c-brand-purple: $c-seance;
$c-brand-purple-text: $c-pure-white;

$c-danger: $c-red;
$c-disabled: $c-grey-dove;
$c-error: $c-red;
$c-success: $c-chateau-green;
