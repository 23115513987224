@import './../../../app/colors.variables';
@import "src/app/theme.module";

#create-company-sidebar {
  padding-right: 1.5rem;
  padding-top: 1rem;

}

@media screen and (max-width: $xs) {
  #create-company-sidebar {

  }
}
