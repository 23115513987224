@import './../../../app/colors.variables';
@import "src/app/theme.module";

#create-company-data6 {

  .users {
    margin-top: 2rem;
  }
  .row-user {
    margin-top: 1rem;
  }

  .add-access {
    margin-top: 2rem;
  }

  .invite-button {
    margin-top: 1rem;
    width: 50%;
  }

  .icon-delete {
    cursor: pointer;
  }
}

@media screen and (max-width: $xs) {
  #create-company-data {

  }
}
