@import "src/app/theme.module";
.input-select{
  width: 90%;
  align-self: center;
  // override mui styleszw

}


:export {
  border-radius: $spacing-4
}
