@import './../../../app/colors.variables';
@import "src/app/theme.module";


#home-performances {
  padding: 1rem;
  margin-top: 2rem;

  .accordion {
    margin-block: 1rem;
  }

  .accordion-label {
    font-weight: bold;
    color: $primaryColor;
    font-size: 1.2rem;
  }

}
