
@import 'src/app/colors.variables';
@import 'src/app/theme.module';

.b-card-login{
  $width: 408px;
  width: $width;
  h1{
    font-size: 24px;
  }
  h1{
    margin-block-start: 0;
    margin-block-end: 1em;
  }

  .e-button{
    margin-block-start: 1em;
  }

  &__clear-email{
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    margin-inline-start: .5em;
    padding:0;
    background:none;
    border:none;
    outline:0;

    svg{
      display: block;
      flex-basis: 100%;
      height:100%;
      width:100%
    }
  }

  &__social{
    margin-block-end: 1em
  }

  &__tip{
    text-align:center;
    color: $c-grey-dove;
  }

  &__warn{
    margin-block-end:0
  }

  &__content{
    > :last-child{
      margin-block-end: 0
    }
  }
}
