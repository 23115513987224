@import "src/app/theme.module";

#landing-content {
  display: flex;
  width: 100%;
  height: 100%;

  .logo-image {
    width: 4rem;
    margin-left: 1rem;
    margin-top: 1rem;
    z-index: 1;
  }

  .content {
    border-radius: 8px;
    position: relative;
    height: 80vh;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-size: cover;
  }

  .text {
    opacity: 0.8;
    border: 2px solid $primaryColor;
    background-color: white;
    height: 10rem;
    width: 14rem;
    border-radius: 8px;
    padding-inline: 0.2rem;
    overflow: hidden;
  }
}
