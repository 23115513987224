@import "src/app/theme.module";

#icon {
  display: flex;
  align-items: center;
  justify-content: center;

  .primary {
    color: $primaryColor!important;
    background-color: transparent!important;
  }

  .secondary {
    color: $secondaryColor;
    background-color: transparent!important;
  }

  .black {
    color: $black;
    background-color: transparent!important;
  }

  .grey {
    color: $greyBackground;
    background-color: transparent!important;
  }

  .pointer {
    cursor: pointer
  }
}
