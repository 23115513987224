@import './../../../app/colors.variables';
@import "src/app/theme.module";

#step-category-map {
  width: 125%;
  .map-wrapper {
    display: grid;
    grid-template-columns: 6fr 3fr;
  }
  .map {
    width: 100%;
    height: 40rem;
    margin-top: 1rem;
  }
  .App-map {
    width: 100%;
    height: 30rem;
  }

  .chip-container {
    padding: 0 2rem;
    z-index: 4;
  }

  .map-controller {
    padding: 0.5rem
  }

  .chip {
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 0.5rem;
    margin-right: 1rem;
    padding: 0 1rem;
    width: 6rem;
  }
}

.semaphore-mew {
  margin: 0.5rem;
  background-color: black;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.5rem;
  height: 6.5rem;
  width: 2rem;
}

.sem-texts {
  flex-direction: column;
  display: flex;
  min-height: 10em;
}

.sem-circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  background-color: white;

  &.green {
    background-color: green;
  }

  &.red {
    background-color: red;
  }

  &.yellow {
    background-color: yellow;
  }

  &.orange {
    background-color: orange;
  }
}

@media screen and (max-width: $sm) {
  #step-category-map {
    .map-wrapper {
      flex-direction: column;
      display: flex;
    }
  }

  .sem-texts {
    flex-direction: row;
    display: flex;
  }
}
