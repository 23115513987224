@import "src/app/theme.module";

#checkbox-row {
  .privacyContent {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    width: 85%
  }

  .privacyText {
    text-align: left;
  }

  .textContainer {
  }

  .privacyLink {
    color: $primaryColor;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .checkbox-container {
    width: 10%;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: $sm) {
  #checkbox-row {
    .privacyContent {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
      width: 85%
    }

    .privacyText {
      text-align: left;
      font-size: 1rem;
    }

    .textContainer {
    }

    .privacyLink {
      color: $primaryColor;
      text-decoration-line: underline;
      font-size: 1rem;
      cursor: pointer;
    }

    .checkbox-container {
      width: 10%;
      justify-content: center;
      align-items: center;
    }
  }
}
