@import './../../../app/colors.variables';
@import "src/app/theme.module";

.option {
  padding: 5px 0;
}

.label,
.value {
  display: inline-block;
  vertical-align: middle;
}

.label {
  width: 180px;
}

.value {
  width: 45%;
}

.influencer {
  color: $secondaryColor;
  font-weight: bold;
}


.custom-task-edit-tooltip {
  font-family: 'Kanit', sans-serif;
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem;
  border-radius: 1rem;
}

.custom-task-edit-tooltip::before {
  border-top-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.custom-tooltip-duration {
  flex-direction: row;
}
