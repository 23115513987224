@import './../../../app/colors.variables';
@import "src/app/theme.module";

#modal-bank-transfer {

  .modal-body{
    font-weight: bold;
    margin-block-end: 0;
  }

  .modal-reminder {
    //margin-block-start: $l-spacing-2;
    //margin-block-end: $l-spacing-4;
  }

  .modal-controls {
    display: flex;
    justify-content: space-between;
    //margin-block-end: $l-spacing-2;


  }
  .button {
    width: 40%;
  }
}

@media screen and (max-width: $xs) {
  #create-company-data {

  }
}
