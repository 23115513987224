@import "../../../../app/theme.module";

#campaign-modal-recap-brief {
  .image {
    width: 15rem;
    border: 2px solid $primaryColor;
    margin-top: 1rem;
    margin-right: 1rem;
    object-fit: contain;
  }
  .images {
    //overflow: scroll;
  }
}
