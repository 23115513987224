
#signup-verify {
  .container-signup-verify {
    padding: 0.5rem 10rem;
  }

  .image {
    height: 50vh;
  }
}
