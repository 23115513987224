@import 'src/app/colors.variables';
@import "src/app/theme.module";

#buttonBranded {
  .button-branded {
    border-radius: $spacing-4;
    color: $textGrey;
    border-color: $primaryColor;
    background-color: $white!important;
    height: $spacing-4;
    text-transform: none;
    font-style: normal;
    font-weight: 700;
    margin-top: $spacing-2
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}
