@import 'src/app/colors.variables';
@import 'src/app/theme.module';

  .login-button {
    margin: 1rem 0!important;
  }

  .b-card-login{
    border: 0 solid white;
    h1{
      font-size: 24px;
      margin-block-start: 0.2em;
      margin-block-end: 1em;
    }

    .e-button{
      margin-block-start: 1em;
    }

    &__clear-email{
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
      width: 1em;
      height: 1em;
      margin-inline-start: .5em;
      padding:0;
      background:none;
      border:none;
      outline:0;

      svg{
        display: block;
        flex-basis: 100%;
        height:100%;
        width:100%
      }
    }

    &__social{
      margin-block-end: 1em
    }

    &__tip{
      text-align:center;
      margin-block-end: 0;
      color: $c-grey-dove;
    }

    &__warn{
      margin-block-start: 0.5rem;
      color: red
    }

    &__content{
      > :last-child{
      }
    }
  }

