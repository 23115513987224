@import 'src/app/colors.variables';
@import "src/app/theme.module";

#baseModal {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  .container {
    border-radius: 1.5rem;
    min-width: 50vh;
  }
}
