@import './../../../app/colors.variables';
@import "src/app/theme.module";

#socialButtons {
  .button {
    color: $primaryColor;
    background-color: $white;
    border-color: $primaryColor;
    margin-top: $spacing-2;
    margin-block-start: 1em;
  }


}
