@import "src/app/theme.module";

#payment-success {
  .image-payment{

  }

}

.link-success {
  color: $primaryColor;
  font-size: 0.8rem;
  padding-left: 1rem;
  cursor: pointer;
  text-decoration-line: underline;
}
