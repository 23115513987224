@import "../../../app/theme.module";

#input-file {
  .button {
    width: 8rem;
  }


  .dark-theme {

  }
}

@media screen and (min-width: $xs) {
  #input-file {

  }
}

@media screen and (min-width: $sm) {
  #input-file {

  }
}
