@import "src/app/theme.module";
.input-date-time-picker {
  width: 90%;
  height: 60px;
  // override mui styles

}


:export {
  //border-radius: $spacing-0
}
