@import "src/app/theme.module";

#campaign-stat-inf {
  margin-top: 4rem;

  .inf-profile {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2rem;
  }

  .inf-nick {
    font-weight: bold;
    color: $primaryColor;
    margin-left: 0.5rem;
  }
}

#modal-inf {
  .label-inf {
    font-weight: 800;
  }

  .row-inf-container {
    margin-bottom: 0.5rem;
  }

}
