@import "src/app/theme.module";

#influencer-page {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.inf-img {
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  margin-right: 1rem;
}

.inf-cmp-row {
  margin-bottom: 1rem;
  border-radius: 1rem;
  padding: 0.5rem;
  background-color: $greyDefault;
  cursor: pointer;
}
.inf-cmp-row-engagement {
  cursor: initial !important;
}
.inf-avatar {
  height: 6rem;
  width: 6rem;
  border-radius: 3rem;
}

.inf-header {
  height: 6rem;
  border-radius: 3rem;
  background-image: linear-gradient(to left, rgba(225, 221, 230, 0), rgba(225, 221, 230, 1));
  padding-right: 1rem;
}

.inf-about {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.logo-transparent {
  height: 5.5rem;
  object-fit: contain;
  display: none;
}

.logo-transparent-long {
  height: 5.5rem;
}

.inf-nickname {
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 0.5rem;
}

.inf-aboutMe {
  margin-top: 1rem
}

.inf-campaigns {
  margin-top: 1rem;
}

.header-first {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: $sm) {
  #influencer-page {
    margin-top: 0;
  }

  .inf-header {
    height: 6rem;
    border-radius: 0;
    background-image: linear-gradient(to left, rgba(225, 221, 230, 0), rgba(225, 221, 230, 1));
    padding-right: 1rem;
  }

  .logo-transparent-long {
    display: none;
  }

  .logo-transparent {
    display: block;
  }

  .inf-avatar {
    height: 5rem;
    width: 5rem;
    border-radius: 2.5rem;
    margin-left: 1rem;
  }
}
