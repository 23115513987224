@import "src/app/theme.module";

#home-header{
  padding: 0.5rem;
  justify-content: center;

  .avatar {
    width: 8rem!important;
    height: 8rem!important;
    border-radius: 4.5rem;
    border: 2px solid $primaryColor;
    margin-right: 1rem;
  }

  .avatar-user {
    width: 2rem;
    height: 2rem;
    border-radius: 4rem;
  }

  .profileInfo {
    padding: .5rem;
  }

  .bun-name {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .create-campaign {
    margin-top: 0.5rem;
  }
}

.card-statistic {
  margin: 0.5rem;
  border-radius: 2rem;
  margin-inline: 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  position: relative;
  overflow: hidden;
}

.card-statistic-label {
  color: $primaryColor;
  font-weight: bold;
  font-size: 1rem;
  align-self: center;
}
.title-bar {
  height: 100px;
  width: 100px;
  border-radius: 100px;
  position: absolute;
  right: -50px;
  top: -50px;
  //&:before {
  //  content: "";
  //  border-color: transparent #FFFFFF transparent transparent;
  //  top: 0;
  //  right: 0;
  //  background-color: red;
  //  position: absolute;
  //  height: 50px;
  //  width: 50px;
  //  overflow: hidden;
  //}
}
.card-statistic-value {
  color: $black;
  font-weight: bold;
  font-size: 2rem;
}
