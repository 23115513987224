@import "src/app/theme.module";

#bun-detail {
  padding: 2rem;

  .bun-detail-image {
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    margin-right: 1rem;
  }

}

.cursor-pointer {
  cursor: pointer;
}
