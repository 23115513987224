@import './../../../app/colors.variables';
@import "src/app/theme.module";

#campaign-stepper {
  .c-campaign-stepper__list {
    background-color: $c-pure-white;
    border-radius: $spacing-3;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    padding: $spacing-3;
  }

  .c-campaign-stepper__item {
    display: flex;
    align-items: center;

    &.is-completed {
      .c-campaign-stepper__index {
        color: $c-success;
        font-weight: bold;
        border: 1px solid $c-success;
      }
    }

    &.is-selected {
      color: $c-brand-purple;
      font-weight: bold;

      .c-campaign-stepper__index {
        background-color: $c-brand-purple;
        color: $c-brand-purple-text;
        font-weight: bold;
      }

      .c-campaign-stepper__label {
        font-weight: bold;
      }
    }
  }

  .c-campaign-stepper__label {
    margin-inline-start: 1rem;
  }
}
