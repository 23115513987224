@import "src/app/theme.module";

.e-input-textarea{
  width: 100%;

  .MuiInputLabel-root {
    color: $primaryColor;
    position: static;
    transform: none;
    margin-block-end: 1rem;
    font-weight: bold;
  }

  legend {
    display: none;
  }

  .e-input-charcounter {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    border-radius: 1rem;
    display: block;
    height: auto;
    padding: 0.25rem 0.75rem;
    max-height: none;
    background: $c-grey-mischka;

    &.is-success {
      color: $successColor;
    }

    &.is-error {
      color: $errorColor!important;
    }
  }

}


:export {
  border-radius: $spacing-4
}
