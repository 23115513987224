@import "src/app/theme.module";

#modal-contact-form {
  .subtitle {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.insert-link {
  text-decoration-line: underline;
  font-size: 11px;

}

.notCodeYet {
  color: $textGrey;
  &.small {
    font-size: 11px;
  }
}

.verify-link {
  text-decoration-line: underline;
}
