@import './../../../app/colors.variables';
@import "src/app/theme.module";

#home-banner {
  margin-top: 1rem;

  .container {
    background-color: $secondaryColor;
    border-radius: 16px;
    width: 100%;
    padding: 0.5rem 1rem
  }

}
