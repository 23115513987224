@import "src/app/theme.module";

#step-category {
  .grid-radio {
    margin-top: 1.5rem;
  }

  .row-date {
    margin-top: 1.5rem;
  }

  .semaphore {
    width: 1rem;
    height: 1rem;
    margin-top: 5px;
    margin-right: 5px;
  }

  .map-wrapper-cat {
    flex-direction: row;
    display: flex;
  }

  .map-command {

  }
}

@media screen and (max-width: $sm) {
  #step-category {
    .map-wrapper-cat {
      flex-direction: column;
      display: flex;
    }
  }
}
