@import './../../../app/colors.variables';
@import "src/app/theme.module";

.social-container {
  margin-block: 4px;
}

.bnt-text {
  font-size: 0.8rem;
}

.card-view-detail {
  font-size: 0.8rem;
}
.card-view-detail-click {
  color: $primaryColor;
  font-size: 0.8rem;
  cursor: pointer;
  //overflow:hidden;
  //white-space:nowrap;
  //text-overflow:ellipsis;
}

.pointer {
  cursor: pointer;
}

.link {
  margin-block: 0.3rem;
}

.b-card-recap {
  padding: 1.5rem;
  padding-block-start: $spacing-2;
  display: flex;
  flex-direction: column;



  // dl {
  //   display: flex;
  //   flex-flow: row wrap;
  //   align-items: center;

  // }
  // dt {
  //   flex-basis: 70%;
  //   display: flex;
  //   align-items: center;
  //   gap:$l-spacing-3;

  //   &:nth-last-child(1) {
  //     font-weight: bold;
  //   }
  // }
  // dd {
  //   flex-basis: 30%;
  //   margin: 0;
  //   text-align: right;
  // }
  ul {
    list-style: none;
    padding: 0;
    margin-block: $spacing-2 0;

    li {
      display: flex;
      align-items: center;
    }
  }

  .text-max3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.b-card-recap__list {
  &:first-of-type {
    margin-block-start: $spacing-4;
  }
}

.b-card-recap__list-item {
  justify-content: space-between;
  font-weight: bold;

  & + & {
    padding-block-start: $spacing-3;
  }
}

.b-card-recap__prices {
  .b-card-recap__list-item {
    padding-inline-start: 2.5rem;
    font-weight: normal;

    &:last-child {
      font-weight: bold;
    }
  }

  &.b-card-recap__prices--total {
    li:last-child {
      padding-inline-start: 0rem;
    }
  }
}

.b-card-recap__label {
  display: flex;
  align-items: center;
  gap: $spacing-3;
}

.b-card-recap__chip {
  background: $c-grey-mischka;
  color: $c-brand-purple!important;
  font-weight: bold;
  align-self: flex-end;
  text-transform: uppercase;
}

.b-card-recap__titleSection {
  * {
    font-weight: 700;
    font-family: 'Nunito';
  }

  h3 {
    color: $c-brand-purple;
  }
}

.b-card-recap__controls {
  display: flex;

  button {

    &.right {
      width: 60%;
      margin-left: 4px;
    }

    &.left {
      width: 40%;
      margin-right: 4px;
    }
  }
}

#budget-campaign {
  .bold {
    font-weight: bold;
  }
}

hr {
  border: 1px solid $c-grey-mischka;
  width: 100%;

  + hr {
    display: none;
  }
}
