@import "src/app/theme.module";


#cashout-list {
  padding: 1rem
}

.dialog-label {
  font-weight: 600;
  margin-right: 0.5rem;
}

.dialog-value {
  font-weight: 300;
  margin-right: 0.5rem;
}

.dialog-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.cursor-pointer {
  cursor: pointer;
}
