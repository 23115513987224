@import "src/app/theme.module";

#create-campaign {
  margin-top: $spacing-4;
}

.c-select-campaign__title {
  font-size: 2rem;
  padding-block: $spacing-1;
}

.c-select-campaign__row {
  display: flex;
  gap: 2rem;
}

.c-select-campaign__button {
  width: 100%;
  border-radius: $spacing-3;
  font-size: 1.25rem;
  min-height: 3.875rem;
}

.c-select-campaign__grid {
  margin-top: $spacing-3;
}
