@import './../../../app/colors.variables';
@import "src/app/theme.module";



#header {
  .e-header {
    min-height: 6.625rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 $spacing-3;
    justify-content: space-between;
    color: $c-pure-white;

    &.black {
      background: $c-brand-black;
    }

    &.white {
      background: $white
    }
  }

  .btn-shopping {
    background-color: transparent;
  }

  .bun-avatar{
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    margin-right: 0.5rem;
  }

  .header-col {
    min-height: 6.625rem - 2rem;
  }

  .header-logo {
    height: 4rem;
  }

  .header-logo-small {
    display: none;
  }

  .e-header__link {
    font-weight: bold;
    cursor: pointer;

    &:active,
    &:hover:not([aria-disabled]),
    &[aria-current]:not([aria-disabled]) {
      color: $c-brand-yellow;
    }
  }

  .e-header__menu .MuiPaper-root {
    background-color: $c-brand-black-75;
    color: $c-pure-white;
  }

  .e-header__menu .MuiMenuItem-root:hover,
  .e-header__menu .MuiMenuItem-root.Mui-selected {
    background-color: $c-brand-black;
  }

  .e-header__signin {
    height: 36px;
    border-radius: 20px;
  }

  .menu {
    position: absolute;
    z-index: 1;
    right: -16px;
    top: 32px;
    width: 14rem;

    background-color: black;
    padding-right: 0.5rem;
    border: 0 solid $primaryColor;
    border-top-width: 1px;

    &.Enchorer {
      height: 5rem;
    }

    &.BusinessUser {
      height: 10rem;
    }
   }

}

@media screen and (max-width: $xs) {
  #header {
    .header-logo {
      display: none;
    }
    .header-logo-small {
      display: block;
      height: 4rem;
    }
  }
}
