@import './../../../app/colors.variables';
@import "src/app/theme.module";

#campaign-cart-row {
  margin-bottom: 2rem;
  width: 100%;

  .creation {
  }

  .typeof {
    font-size: 1.25rem;
  }

  .brief-value {
    width: 80%;
    text-align: right;
  }
}


.chart-icon{
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
}
