@import "src/app/theme.module";

.container {
  display: flex;
  //justify-content: center;
  padding: $spacing-1;

  .content-block {
    width: 100%;
  }
  .noPadding {
    padding: 0 !important;
  }
}

@media screen and (max-width: $xs) {
  .container {
    padding: $spacing-1 0;

    .content-block {
      max-width: 90%
    }
  }
}
