@import 'src/app/colors.variables';
@import 'src/app/theme.module';

#landing-page-creator {
  padding: 2rem;

  .subtitle {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .file-button {
    margin-top: 1rem;
    min-width: 12rem;
  }

  .image {
    width: 100%;
    border: 1px solid $primaryColor;
  }
}
