@import "src/app/theme.module";

.info {
  padding-inline: 0.5rem
}

.bold {
  font-weight: bold;
}

.section-container {
  margin-top: 0.5rem;
}

.social-container {
  margin-block: 0.5rem;
}

.truncate {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-container {
  margin-top: 0.5rem;
}

.social-container {
}

.container {
  flex-wrap: wrap;
}

.button-bottom {
  padding-inline: 1rem;
}
.action-container {
  margin-top: 1.5rem;
}

#campaign-detail {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
