@import "src/app/theme.module";

#profile-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item {
  margin-top: $spacing-2!important;
  height: 10rem;
  justify-content: center;
  align-items: center;
}

.avatar {
  height: 6rem!important;
  width: 6rem!important;
}
