@import "src/app/theme.module";

.privacyText {
  color: $textGrey
}

.text-container {
  margin-top: 0.1rem;
  margin-bottom: 0.5rem;
}

.privacyLink {
  color: $primaryColor;
  text-decoration-line: underline;
  cursor: pointer;
}

.privacy-container {
  padding-left: 0.5rem;
}
