@import './../../../app/colors.variables';
@import "src/app/theme.module";

#step-social {
  .grid {
    display: grid !important;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-items: start;
  }

  .label {
    display: inline-grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
  }

  .icon {
    width: 40px;
    height: 40px;
  }
}

.hashtag{
  font-weight: bold;
  font-size: 1.5rem;
}
