@import "src/app/theme.module";

.campaignStatus {
  width: 10rem;
  height: 2rem;
  border-radius: 1rem;

  &.to-be-approved {
    background-color: $secondaryColor;
    color: $black
  }

  &.approved {
    background-color: $primaryColor;
    color: $white
  }

  &.draft {
    background-color: $greyBackground;
    $color: $white
  }

  &.dismissed {
    background-color: $greyBackground;
    $color: $white
  }
}

.icon-actions {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.5rem
}


#campaign-list {
  padding: 1rem;

  .button-download {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    color: white;
    background-color: $primaryColor;
    margin-left: 1rem;
  }
}

