@import "src/app/theme.module";

#campaign-statistic {
  padding: 1rem
}

.section {
  margin-block: .5rem;
}

.cmp-id-more {
  margin-top: -0.5rem;
  width: 100%;
  text-align: center;
  color: $primaryColor;
  font-size: 0.8rem;
  cursor: pointer;
  text-decoration-line: underline;
  margin-bottom: 0.5rem;
}
